import { Fragment } from 'react';

import fallbackImage from '../../../assets/car-shadow-dark.png';
import { unit } from '../../../common/theme';
import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { Icon } from '../Icon/Icon';
import { IconTypes } from '../Icon/iconTypes';
import { RotationDirections } from '../Icon/rotationDirections';
import { MainLayoutHeaderTitle } from '../MainLayoutStyles/styles';
import { SmoothImage } from '../SmoothImage/SmoothImage';
import { Tabs } from '../tabSelect/Tabs';
import {
  BackgroundFillSquare,
  GoBack,
  HeaderBorder,
  IconWrapper,
  ImageContainer,
  LogoContainer,
  LogoWrapper,
  TitleContainer,
  VehicleHeaderContainer,
} from './styles';

const VehiclePageHeaderComponent = ({
  breadCrumbs,
  image,
  isMobile,
  logo,
  navigate,
  selectedTab,
  tabs,
  title,
}: {
  breadCrumbs?: string[];
  image?: string | undefined;
  isMobile?: boolean;
  logo?: string;
  navigate?: any;
  selectedTab?: string;
  tabs?: any;
  title?: string;
}) => (
  <Fragment>
    <GoBack $isMobile={isMobile}>
      <IconWrapper $isMobile={isMobile}>
        <Icon
          cursor="pointer"
          height={unit[3]}
          name={IconTypes.ARROW}
          rotate={RotationDirections.LEFT}
          onClick={() => navigate('../..')}
        />
      </IconWrapper>
      <LogoWrapper>
        <LogoContainer data-testid="brand-logo" src={logo} />
      </LogoWrapper>
    </GoBack>
    <VehicleHeaderContainer>
      <ImageContainer $isMobile={isMobile}>
        {isMobile && <BackgroundFillSquare></BackgroundFillSquare>}
        <SmoothImage
          fallbackSrc={fallbackImage}
          src={image}
          testId="vehicleImg"
        />
      </ImageContainer>
      <TitleContainer $isMobile={isMobile}>
        <Breadcrumbs pagesList={breadCrumbs} />
        <MainLayoutHeaderTitle style={{ marginTop: unit[1] }}>
          {title}
        </MainLayoutHeaderTitle>
      </TitleContainer>
    </VehicleHeaderContainer>
    {!!tabs && (
      <Tabs
        navigate={navigate}
        selectedTab={selectedTab}
        siblingRouteDepth="./"
        tabs={tabs}
        isSeparated
      />
    )}
    <HeaderBorder />
  </Fragment>
);

export const VehiclePageHeader = withMatchMedia(VehiclePageHeaderComponent);
