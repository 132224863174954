import { fromPairs, map } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IConfig, UnleashClient } from 'unleash-proxy-client';

import { config } from '../../../config';
import { FeatureFlag } from '../../../hooks/useFeatureFlags/featureFlagsEnum';
import { FeatureFlagsContext } from './FeatureFlagsContext';

export const withFeatureFlagsContextProvider = (WrappedComponent: any) => {
  return (props: any) => {
    const [clientInitialized, setClientInitialized] = useState<boolean>(false);
    const [clientUserId, setClientUserId] = useState(null);

    const client = useMemo(() => {
      const instance = new UnleashClient(config.unleash as IConfig);
      instance.start();
      return instance;
    }, []);

    useEffect(() => {
      client.on('synchronized', () => setClientInitialized(true));

      if (clientUserId) {
        client.setContextField('userId', clientUserId);
      }
    }, [client, clientUserId]);

    const currentFeatureFlagStates = useCallback(
      () =>
        fromPairs(
          map(client.getAllToggles(), ({ name, enabled }) => [name, enabled]),
        ),
      [client],
    );

    const isEnabled = useCallback(
      (feature: FeatureFlag) => {
        return client.isEnabled(feature) || false;
      },
      [client],
    );

    return (
      <FeatureFlagsContext.Provider
        value={{
          clientInitialized,
          currentFeatureFlagStates,
          isEnabled,
          setClientUserId,
        }}
      >
        <WrappedComponent {...props} />
      </FeatureFlagsContext.Provider>
    );
  };
};
