import { useContext, useMemo } from 'react';

import { useModal } from '../../hooks/useModal/useModal';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import { DynamicBrandingContext } from '../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { SearchParamKey } from '../../services/urlParams/urlParams';
import { urlParamHook } from '../../services/urlParams/urlParamsService';
import { Checkbox } from '../shared/checkbox/Checkbox';
import { defaultModalButtons } from '../shared/Modal/Modal';
import { Modal } from '../shared/Modal/Modal';
import { ApplicationProgressStatus, getSteps } from './config';
import {
  CheckboxContainer,
  Description,
  InfoContainer,
  Panel,
  Title,
} from './styles';

export const ApplicationProgress = ({ application }: any) => {
  const {
    checklightSession: { status, autopayNumber },
  } = application.currentVehicle;
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);
  const workflow = useWorkflowPathname();

  const { close } = useModal();

  const { removeParam: removeShowProgressModal } = urlParamHook(
    SearchParamKey.showProgressModal,
  );

  const { removeParam: removeAutopayNumber } = urlParamHook(
    SearchParamKey.autopayNumber,
  );

  const parsedSteps = useMemo(
    () => getSteps(status, workflow),
    [status, workflow],
  );

  const closeModal = () => {
    removeShowProgressModal();
    removeAutopayNumber();

    close();
  };

  return (
    <Modal
      buttonGroupPresModel={defaultModalButtons()}
      subtitle={autopayNumber}
      subtitleColor={$theme.colors.primaryActive}
      testId="selectedLoanProgressPopup"
      title="Application progress"
      onClose={closeModal}
    >
      {parsedSteps.map(({ status, title, description }, index) => (
        <Panel
          $isInactive={status !== ApplicationProgressStatus.Passed}
          $isLast={index === parsedSteps.length - 1}
          key={index}
        >
          <CheckboxContainer>
            <Checkbox
              checked={status === ApplicationProgressStatus.Passed}
              isDisabled={status === ApplicationProgressStatus.Inactive}
              testId="selectedLoanProgressStepCheckbox"
              viewOnly
            />
          </CheckboxContainer>
          <InfoContainer>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </InfoContainer>
        </Panel>
      ))}
    </Modal>
  );
};
