import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { unit } from '../common/theme';
import { Loader } from '../components/shared/Loader/Loader';
import { useAPI } from '../hooks/useAPI/useAPI';
import { useBranding } from '../hooks/useBranding/useBranding';
import { useCurrentUserContext } from '../hooks/useCurrentUserContext/useCurrentUserContext';
import { useErrorReporting } from '../hooks/useErrorReporting/useErrorReporting';
import { useFeatureFlags } from '../hooks/useFeatureFlags/useFeatureFlags';
import { UserContextT } from '../providers/context/CurrentUserContext/CurrentUserContextT';

function RequireAuth({ children }: { children: JSX.Element }) {
  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();
  const [fetchSessionUserComplete, setFetchSessionUserComplete] =
    useState(false);
  const { branding } = useBranding();
  const { id: authenticatedUserId } = currentUserContext;
  const { setClientUserId, currentFeatureFlagStates } = useFeatureFlags();
  const { setErrorsUser, setErrorsAdditionalData } = useErrorReporting();
  const api = useAPI();
  const location = useLocation();

  const fetchSessionUserCompleted = (userData?: UserContextT) => {
    // setup user based plugin configurations
    if (userData) {
      setClientUserId(userData.id);
      setErrorsUser(userData);
    }

    // setup error reporting additional data configuration
    setErrorsAdditionalData({
      ...currentFeatureFlagStates(),
      brand: branding.brandName,
    });

    setFetchSessionUserComplete(true);
  };

  useEffect(() => {
    if (authenticatedUserId) {
      fetchSessionUserCompleted(currentUserContext);
      return;
    }
    api
      .get('/users/current')
      .then((userData: any) => {
        const currentUser = { ...currentUserContext, ...userData };

        setCurrentUserContext(currentUser);
        fetchSessionUserCompleted(currentUser);
      })
      .catch((e) => {
        fetchSessionUserCompleted(currentUserContext);
      });
  }, []);

  if (!fetchSessionUserComplete) {
    return <Loader size={unit[6]} fullScreen />;
  }
  if (!authenticatedUserId) {
    return <Navigate state={{ from: location }} to="./sign-up" replace />;
  }
  return children;
}

export default RequireAuth;
