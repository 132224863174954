import { get, merge, uniqueId } from 'lodash';
import { Fragment, useEffect, useMemo } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  AuthType,
  DeliveryMechanism,
  HttpStatusCode,
  RateSelectedErrors,
} from '../../common';
import { LeadChannel } from '../../common/interfaces/branding/leadChannel';
import { PillowForm } from '../../components/PillowForm';
import PrequalifiedRate from '../../components/PrequalifiedRate/PrequalifiedRate';
import { urlParamsError } from '../../constants/urlParamsError';
import { useAPI } from '../../hooks/useAPI/useAPI';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import { SearchParamKey } from '../../services/urlParams/urlParams';
import { urlParamHook } from '../../services/urlParams/urlParamsService';
import { mapErrorsToFormFields } from '../SignIn/Controller';
import {
  ApplicationUpdateMessage,
  applicationUpdateMessageHeaderContent,
} from './applicationUpdateMessage';
import model from './Model';
import { DecreaseHeaderFormMargin, LeadChannelLogoContainer } from './styles';

const Controller = () => {
  const navigate = useNavigate();
  const rootPath = useWorkflowPathname();

  const {
    savedParam: applicationUpdateMessage,
  }: { savedParam: ApplicationUpdateMessage } = urlParamHook(
    SearchParamKey.applicationUpdateMessage,
  );

  const { savedParam: errorMessage } = urlParamHook<RateSelectedErrors>(
    SearchParamKey.error,
  );
  const { savedParam: leadChannelCode } = urlParamHook(
    SearchParamKey.leadChannelCode,
  );
  const { savedParam: autopayNumber } = urlParamHook(
    SearchParamKey.autopayNumber,
  );
  const { savedParam: phoneNumber } = urlParamHook(SearchParamKey.phoneNumber);
  const { savedParam: amount } = urlParamHook(SearchParamKey.amount);
  const { savedParam: term } = urlParamHook(SearchParamKey.term);
  const { savedParam: apr } = urlParamHook(SearchParamKey.apr);

  // viewedOffers is being used to track all of the applications for which a user has viewed an updated offer.
  // it is an array of autopayNumbers.
  const { savedParam: viewedOffers, saveParam: saveViewedOffers } =
    urlParamHook(SearchParamKey.viewedOffers);

  const parsedViewedOffers = JSON.parse(viewedOffers || '[]');

  const isDisplayedOfferInformation =
    (applicationUpdateMessage === ApplicationUpdateMessage.TERMS_UPDATED ||
      applicationUpdateMessage === ApplicationUpdateMessage.HAS_APPROVAL) &&
    [amount, term, apr].every(Boolean);

  useEffect(() => {
    if (
      autopayNumber && // autopayNumber query parameter is provided
      isDisplayedOfferInformation && // Offer information is displayed
      !parsedViewedOffers.includes(autopayNumber) // Offer has not been viewed for this autopayNumber (application)
    ) {
      // create a note for the application that the user has viewed the offer
      api
        .post('/vehicles/null/application/note', {
          autopayNumber,
          applicationEventLogType: 'CONSUMER_PORTAL',
          applicationStatusName: 'Viewed offer',
          applicationStatusNote: `${autopayNumber} - Consumer viewed offer update`,
        })
        .then(() => {
          // save the autopayNumber to the list of viewed offers
          const updatedViewedOffers = [...parsedViewedOffers, autopayNumber];

          saveViewedOffers(JSON.stringify(updatedViewedOffers));
        });
    }
  }, [autopayNumber, isDisplayedOfferInformation]);

  const leadChannelData = useAsync(
    () =>
      api.get<any, LeadChannel>(
        `/ui-configuration/lead-channel/${leadChannelCode}`,
      ),
    [leadChannelCode],
  );

  const methods = useForm();

  const api = useAPI();

  const onSubmit = useAsyncCallback(
    async ({ primaryPhoneNumber }: { primaryPhoneNumber: string }) => {
      try {
        await api.post('pin/deliver', {
          purpose: AuthType.ACCOUNT_SIGN_IN_USING_PIN,
          deliverWith: DeliveryMechanism.SMS,
          phone: primaryPhoneNumber,
        });
        navigate(`/${rootPath}/sign-in/verify/pin`, {
          state: { primaryPhoneNumber },
        });
      } catch (e: any) {
        if (
          e.find((error: any) => error.statusCode === HttpStatusCode.NOT_FOUND)
        ) {
          navigate(`/${rootPath}/sign-up/`, {
            state: { invalidPhoneNumber: true },
          });
        }
        throw mapErrorsToFormFields(e);
      }
    },
  );

  const headerBlock = useMemo(() => {
    const leadChannelDisplayLabel = get(leadChannelData, 'result.displayLabel');

    return applicationUpdateMessageHeaderContent(
      applicationUpdateMessage,
      leadChannelDisplayLabel,
    );
  }, [leadChannelData]);

  const beforeHeaderContent = useMemo(
    () =>
      leadChannelData?.result ? (
        <Fragment>
          <LeadChannelLogoContainer
            src={leadChannelData?.result.primaryLogo?.url}
          />
        </Fragment>
      ) : null,
    [leadChannelData],
  );

  const beforeFormContent = useMemo(
    () => (
      <Fragment>
        <DecreaseHeaderFormMargin key={uniqueId('margin_')} />
        {isDisplayedOfferInformation && (
          <PrequalifiedRate
            apr={apr}
            monthlyPaymentAmount={amount}
            shouldDisplayCalculatedMonthlyPayment={true}
            term={term}
          />
        )}
      </Fragment>
    ),
    [isDisplayedOfferInformation],
  );

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel: model },
        {
          presModel: {
            headerBlock,
            form: {
              globalErrors: [urlParamsError[errorMessage]],
              actions: {
                primary: {
                  handler: onSubmit.execute,
                  isDisabled: onSubmit.loading,
                },
              },
              fields: {
                primaryPhoneNumber: {
                  disabled: onSubmit.loading,
                  value: phoneNumber,
                  ...(onSubmit.loading
                    ? {
                        hint: 'Delivering verification code...',
                      }
                    : {}),
                },
              },
              ...onSubmit.error,
            },
          },
          beforeHeaderContent,
          beforeFormContent,
        },
      ),
    [
      headerBlock,
      beforeHeaderContent,
      beforeFormContent,
      onSubmit,
      phoneNumber,
      errorMessage,
    ],
  );

  return <PillowForm {...enhancedProps} methods={methods} />;
};

Controller.displayName = 'ApplicationUpdate.Controller';
export default Controller;
