import { get } from 'lodash';
import { ChangeEvent, Fragment, useCallback, useRef } from 'react';
import TagManager from 'react-gtm-module';

import { truncateFilename } from '../../../services/document/documentService';
import {
  ContentContainer,
  RightContentContainer,
  TextOverflowWrapper,
  TitleContainer,
} from '../listItem/styles';
import { PanelLink } from '../PanelList/styles';
import {
  ErrorText,
  FileElementWrapper,
  FileNameContainer,
  FileUploadInput,
  LeftContentContainer,
  SubtitleContainer,
} from './styles';

const FileUpload = ({
  label,
  disabled,
  onChange,
  testId,
  error,
  isHidden,
  gtm,
  removeable,
  removeClicked,
}: any) => {
  const fileUploadInputRef = useRef<HTMLInputElement>(null);

  const fileName = truncateFilename(
    get(fileUploadInputRef.current, 'files.0.name', ''),
    20,
  );

  const handleClick = useCallback(() => {
    if (fileName && removeable && removeClicked) {
      removeClicked();

      return;
    }

    if (fileUploadInputRef.current) {
      fileUploadInputRef.current.click();
    }
  }, [fileUploadInputRef, fileName, removeable, removeClicked]);

  const updateSelectedFile = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = event;

    // Files array is empty when user clicks cancel on file upload modal
    if (!files?.length) {
      return;
    }

    if (gtm) {
      TagManager.dataLayer({
        dataLayer: gtm,
      });
    }

    onChange(files[0]);
  };

  return (
    <Fragment>
      <FileElementWrapper
        $isHidden={isHidden}
        disabled={disabled}
        onClick={handleClick}
      >
        <ContentContainer data-testid={testId}>
          <LeftContentContainer>
            <TextOverflowWrapper>
              <TitleContainer $largeTitle={true} disabled={disabled}>
                {label}
              </TitleContainer>
            </TextOverflowWrapper>
            <FileNameContainer>
              <SubtitleContainer>
                {fileName || 'Click to upload'}
              </SubtitleContainer>
              {error && <ErrorText>{error.message}</ErrorText>}
            </FileNameContainer>
          </LeftContentContainer>
          <RightContentContainer>
            <PanelLink>
              {fileName && !removeable
                ? 'Edit'
                : fileName && removeable
                ? 'Remove'
                : 'Upload'}
            </PanelLink>
          </RightContentContainer>
        </ContentContainer>
      </FileElementWrapper>
      <FileUploadInput
        accept="image/png, image/jpeg, application/pdf"
        forwardedRef={fileUploadInputRef}
        type="file"
        onChange={updateSelectedFile}
      />
    </Fragment>
  );
};

export const FileUploadControl = (props: any) => {
  const {
    name,
    defaultValue = '',
    validationRules,
    control,
    controller: Controller,
    value,
    trigger,
    isHidden,
    ...otherProps
  } = props;

  const handleChange = useCallback(
    (metadata: any, field: any) => {
      field.onChange({ metadata });
      trigger(name);
    },
    [trigger, name],
  );

  return (
    <Controller
      control={control}
      defaultValue={value !== undefined ? value : defaultValue}
      name={name}
      render={({ field, fieldState: { error } }: any) => (
        <FileUpload
          {...otherProps}
          error={error}
          onChange={(v: any) => handleChange(v, field)}
        />
      )}
      rules={validationRules}
    />
  );
};
