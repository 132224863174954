import { useContext, useMemo } from 'react';

import fallbackVehicleImage from '../../assets/car-shadow-dark.png';
import { UserVehicle } from '../../common/interfaces/vehicle/vehicle.interface';
import { DynamicBrandingContext } from '../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { Modal } from '../shared/Modal/Modal';

export const DuplicateApplicationModal = ({
  currentVehicle,
  onClose,
}: {
  currentVehicle: UserVehicle;
  onClose: () => void;
}) => {
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);

  const image = (
    (currentVehicle.imageList || []).find(
      (img: any) => img.vehicleImageType === 'DEFAULT',
    ) || {}
  ).url;

  const modalButtons = {
    rtl: true,
    primaryButton: {
      name: 'close',
      children: 'Close',
      testId: 'confirm',
    },
  };

  const modalImage = useMemo(
    () => ({
      src: image,
      fallbackSrc: fallbackVehicleImage,
    }),
    [image],
  );

  return (
    <Modal
      buttonGroupPresModel={modalButtons}
      modalImage={modalImage}
      subtitle={`${currentVehicle.make} ${currentVehicle.model}`}
      subtitleColor={$theme.colors.primaryActive}
      title="Please complete your previous application"
      onClose={onClose}
    >
      We've detected that you currently have an active application for this
      vehicle. Please complete any open tasks for this application or contact us
      to cancel.
    </Modal>
  );
};
