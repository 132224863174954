import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { config } from '../../../config';
import { Workflow } from '../../../constants/Workflow';
import { WorkflowInitialContext } from '../../../providers/context/WorkflowInitialContext/WorkflowInitialContext';
import { getAvailableTasks } from '../../../services/task/taskService';
import { withDataCaching } from '../../hoc/withDataCaching';
import { withRoutes } from '../../hoc/withRoutes';
import { Gate } from '../interfaces/gate.interface';
import { GateController } from './GateController';

export const GateRoot = (props: { gate: Gate<any> }) => {
  const { showRoutes, cacheData, name, accessibleForTask } = props.gate;
  const [initialContext] = useContext(WorkflowInitialContext);
  const { currentVehicle } = initialContext || {};
  const { checklightSession = {} } = currentVehicle || {};
  const { status } = checklightSession;
  const [isRestrictedAccess, setIsRestrictedAccess] = useState(false);

  useEffect(() => {
    if (accessibleForTask && !config.debug.gates) {
      const availableTasks = getAvailableTasks(
        initialContext,
        Workflow.unified,
        status,
      );
      setIsRestrictedAccess(!availableTasks.includes(accessibleForTask));
    }
  }, [props.gate.accessibleForTask]);

  // TODO: refactor withRoutes, withDataCaching
  // to get data from gate and remove this.
  const routes = props.gate.steps.reduce((acc, step) => {
    if (step.path === undefined) {
      return acc;
    }
    return {
      ...acc,
      [step.name]: step.path,
    };
  }, {});

  const controllerWithProps = (allProps: any) => (
    <GateController {...allProps} />
  );

  const ComponentWithRoutes = showRoutes
    ? withRoutes(controllerWithProps)
    : controllerWithProps;

  const ComponentWithCaching = cacheData
    ? withDataCaching(ComponentWithRoutes)
    : ComponentWithRoutes;

  if (isRestrictedAccess) {
    return <Navigate to={`/${Workflow.unified}`} />;
  }

  return (
    <ComponentWithCaching {...props} routes={routes} workflowName={name} />
  );
};
