import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { Button, ButtonProps } from '../Button/Button';
import { Container, Controls, Info, Subtitle, Symbol, Title } from './styles';

interface ApplicationProps {
  isMobile?: boolean | undefined;
  title: string;
  subtitle?: string;
  symbol: string;
  isDisabled?: boolean | undefined;
  onView?: () => void;
  onDelete?: () => void;
  buttonsConfig: ButtonProps[];
}

const ApplicationCardComponent = ({
  isMobile,
  title,
  subtitle,
  symbol,
  isDisabled,
  buttonsConfig,
}: ApplicationProps) => {
  return (
    <Container $isMobile={isMobile}>
      <Info>
        {symbol && <Symbol $isDisabled={isDisabled}>{symbol}</Symbol>}
        <Title $isDisabled={isDisabled}>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Info>

      <Controls $isMobile={isMobile}>
        {buttonsConfig.map((btn, i) => (
          <Button key={i} {...btn} isFullWidth={isMobile} isGrouped />
        ))}
      </Controls>
    </Container>
  );
};

export const ApplicationCard = withMatchMedia(ApplicationCardComponent);
