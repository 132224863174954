import { Container, CopyContainer } from './styles';

export const CopyBlock = ({
  asList = false,
  copy,
  identifier,
  blockIndex,
}: any) => {
  return (
    <Container data-testid={`disclaimerText-${blockIndex}`}>
      {copy.map((c: any, i: any) => (
        <CopyContainer
          $hasMargin={!asList}
          key={`${identifier || 'list'}-copy-${i}`}
        >
          {asList ? '- ' : ''}
          {c}
        </CopyContainer>
      ))}
    </Container>
  );
};
