import { Breadcrumb } from './styles';

export type BreadcrumbsProps =
  | {
      page: string;
      onClick?: () => void;
    }
  | string;

export const Breadcrumbs = ({
  pagesList = [],
}: {
  pagesList: BreadcrumbsProps[] | undefined;
}) => {
  const pagesAmount = pagesList.length;

  return (
    <div>
      {pagesList.map((pageConfig, index) => (
        <Breadcrumb
          $isLast={index === pagesAmount - 1}
          key={index}
          {...(typeof pageConfig !== 'string' && 'onClick' in pageConfig
            ? {
                $isClickable: true,
                onClick: pageConfig.onClick,
              }
            : {})}
        >
          {typeof pageConfig === 'string' ? pageConfig : pageConfig.page}
        </Breadcrumb>
      ))}
    </div>
  );
};
