import { useContext } from 'react';

import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { Button } from '../Button/Button';
import {
  ResourceActionButtonContainer,
  ResourceActionContainer,
  ResourceActionSubtitle,
  ResourceActionTitle,
  ResourceActionTitleContainer,
} from './styles';

type ResourceActionProps = {
  actionTitle: string;
  isMobile?: boolean | undefined;
  subtitle: string;
  title: string;
  onClick?: () => void;
};

const ResourceAction = ({
  actionTitle,
  isMobile,
  subtitle,
  title,
  onClick,
}: ResourceActionProps) => {
  const {
    branding: { theme },
  } = useContext(DynamicBrandingContext);

  return (
    <ResourceActionContainer $isMobile={isMobile} $theme={theme}>
      <ResourceActionTitleContainer $isMobile={isMobile}>
        <ResourceActionTitle>{title}</ResourceActionTitle>
        <ResourceActionSubtitle $isMobile={isMobile}>
          {subtitle}
        </ResourceActionSubtitle>
      </ResourceActionTitleContainer>

      <ResourceActionButtonContainer $isMobile={isMobile}>
        <Button isFullWidth={true} onClick={onClick}>
          {actionTitle}
        </Button>
      </ResourceActionButtonContainer>
    </ResourceActionContainer>
  );
};

export default withMatchMedia(ResourceAction);
