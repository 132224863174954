import { round, toNumber, toString } from 'lodash';

import { withMatchMedia } from '../../providers/context/MatchMediaProvider/withMatchMedia';
import { calculateMonthlyPayment } from '../../utils/calculateMonthlyPayment';
import { RateItem, RateItemTitle, RateItemValue, RateWrapper } from './styles';

const PrequalifiedRate = ({
  isMobile,
  monthlyPaymentAmount,
  apr,
  term,
  shouldDisplayCalculatedMonthlyPayment,
}: any) => {
  const toRateNumber = (
    value: string | number,
    precision?: number | undefined,
  ) => {
    const valueSanitized = toString(value).replace(/[^0-9.]/g, '');

    let valueAbsolute = Math.abs(toNumber(valueSanitized));

    if (valueAbsolute < 1) {
      // value is less than 1, it's a decimal, so multiply by 100 to get a percentage value
      valueAbsolute *= 100;
    }

    const valueRounded = round(valueAbsolute, precision);

    return valueRounded;
  };

  const formattedMonthlyPaymentAmount = toRateNumber(monthlyPaymentAmount);
  const formattedApr = toRateNumber(apr, 2);
  const formattedTerm = toRateNumber(term);

  const calculatedMonthlyPaymentAmount = calculateMonthlyPayment(
    formattedApr / 100,
    formattedTerm,
    formattedMonthlyPaymentAmount,
  );

  return (
    <RateWrapper $isMobile={isMobile}>
      <RateItem $isMobile={isMobile}>
        <RateItemTitle $isMobile={isMobile}>Payment</RateItemTitle>

        <RateItemValue data-testid="payment">
          {shouldDisplayCalculatedMonthlyPayment
            ? `$${calculatedMonthlyPaymentAmount}/month`
            : `$${formattedMonthlyPaymentAmount}/month`}
        </RateItemValue>
      </RateItem>

      <RateItem $isMobile={isMobile}>
        <RateItemTitle $isMobile={isMobile}>Rate</RateItemTitle>

        <RateItemValue data-testid="apr">{formattedApr}%</RateItemValue>
      </RateItem>

      <RateItem $isMobile={isMobile}>
        <RateItemTitle $isMobile={isMobile}>Term</RateItemTitle>

        <RateItemValue data-testid="term">{formattedTerm} months</RateItemValue>
      </RateItem>
    </RateWrapper>
  );
};

export default withMatchMedia(PrequalifiedRate);
