import { devtools } from 'zustand/middleware';

import useApiStore from './apiStore';
import { create, storeDevToolOptions } from './storeManager';

type LeadChannelState = {
  initLeadChannelFinished: boolean;
  leadChannel: Record<string, any>;

  initLeadChannel: (
    leadChannelCode: string | null | undefined,
  ) => Promise<void> | undefined;
};

const useLeadChannelStore = create()(
  devtools<LeadChannelState>(
    (set, get) => ({
      initLeadChannelFinished: false,
      leadChannel: {},

      initLeadChannel: (leadChannelCode: string | null | undefined) => {
        if (!leadChannelCode) {
          set({ initLeadChannelFinished: true });
          return;
        }

        const radixHttpRequest = useApiStore.getState().radixApi();

        return radixHttpRequest
          .get(`/ui-configuration/lead-channel/${leadChannelCode}`)
          .then((leadChannel) => {
            set({
              leadChannel,
            });
          })
          .finally(() => {
            set({ initLeadChannelFinished: true });
          });
      },
    }),
    storeDevToolOptions('leadChannelStore'),
  ),
);

export default useLeadChannelStore;
