import PropTypes from 'prop-types';
import { useContext } from 'react';

import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { Icon as IconComponent } from '../Icon/Icon';
import { IconTypes } from '../Icon/iconTypes';
import { RotationDirections } from '../Icon/rotationDirections';
import { DropdownCaret, DropdownContainer, DropdownElement } from './styles';
// $theme.colors.secondaryOverlay
export const TabDropdown = ({ tabs, disabled = false, onChange }: any) => {
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);

  return (
    <DropdownContainer>
      <DropdownElement
        disabled={disabled}
        value={(tabs.find(({ isSelected }: any) => isSelected) || {}).value}
        onChange={(e: any) => onChange(e.target.value)}
      >
        {tabs &&
          tabs.map((tab: any, i: any) => (
            <option key={i} value={tab.value}>
              {tab.label}
            </option>
          ))}
      </DropdownElement>
      <DropdownCaret>
        <IconComponent
          color={disabled ? $theme.colors.disabled : $theme.colors.infoOverlay}
          name={IconTypes.CARET}
          rotate={RotationDirections.UP}
        />
      </DropdownCaret>
    </DropdownContainer>
  );
};

TabDropdown.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      isSelected: PropTypes.bool,
    }),
  ).isRequired,
};
