import { devtools } from 'zustand/middleware';

import useApiStore from './apiStore';
import useParameterStore from './parameterStore';
import useRouteStore, { DEFAULT_AUTH_PATH } from './routeStore';
import { create, resetAllStores, storeDevToolOptions } from './storeManager';

type SignOutOptions = {
  withMessage?: string | null;
  withAuthRedirectPath?: string | null;
};

type SignOutState = {
  isSigningOut: boolean;
  signOutMessage: string | null;

  signOut: (options?: SignOutOptions) => Promise<void>;
};

const useSignOutStore = create({ ignoreReset: true })(
  devtools<SignOutState>(
    (set, get) => ({
      isSigningOut: false,
      signOutMessage: null,

      signOut: (options?: SignOutOptions) => {
        // set the state that determines if the user just signed out.
        // this should be set before the API call to ensure the user is
        // redirected to the correct page in the SessionGuard.
        set({ isSigningOut: true });

        const radixHttpRequest = useApiStore.getState().radixApi();

        return radixHttpRequest
          .delete('/2.0/session')
          .then(() => {})
          .catch(() => {
            // ignore for now..
          })
          .finally(() => {
            // reset all stores to their initial state
            resetAllStores();

            // set the sign out message to display on the sign out page
            set({ signOutMessage: options?.withMessage });

            // the parameterStore is not reset in resetAllStores as we require it to always be persisted
            // so we need to manually reset the token here.
            useParameterStore.setState({ token: null });

            // set the auth redirect path to where we'd want the user to go after signing back in.
            // if not provided, the user will be redirected to the default auth path after sign in.
            useRouteStore
              .getState()
              .setAuthRedirectPath(
                options?.withAuthRedirectPath || DEFAULT_AUTH_PATH,
              );
          });
      },
    }),
    storeDevToolOptions('signOutStore'),
  ),
);

export default useSignOutStore;
