import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { IncomeAdd } from './IncomeAdd';
import { IncomeEdit } from './IncomeEdit';
import { IncomeView } from './IncomeView';

export const Income = () => {
  const routes: RouteObject[] = [
    {
      index: true,
      element: <IncomeView />,
    },
    {
      path: 'edit',
      element: <IncomeEdit />,
    },
    {
      path: 'add',
      element: <IncomeAdd />,
    },
    {
      path: '*',
      element: <Navigate replace={true} to="" />,
    },
  ];

  return useRoutes(routes);
};
