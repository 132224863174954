import { compact, get, isString } from 'lodash';
import React from 'react';

import { Container, Row } from './styles';

type ErrorItem =
  | string
  | {
      statusCode: number;
      errorCode: string;
      message: string;
      testId: string;
    }
  | React.ElementType;

export interface ErrorsBlockProps {
  testId?: string;
  errorsList?: Array<ErrorItem>;
}

export const ErrorsBlock = ({
  errorsList = [],
  testId = 'errorsBlock',
}: ErrorsBlockProps) => {
  const errorsListClean = compact(errorsList);
  const defaultError =
    'There was an unexpected error with an operation, please try again at a later time';
  const errorsConfig: {
    condition: (error: ErrorItem) => boolean;
    value: (error: ErrorItem) => ErrorItem;
    testId: (error: ErrorItem, i: number) => any;
  }[] = [
    {
      condition: React.isValidElement,
      value: (error) => error,
      testId: (error, i: number) => i,
    },
    {
      condition: isString,
      value: (error) => error,
      testId: (error, i: number) => i,
    },
    {
      condition: () => true,
      value: (error) =>
        (get(error, 'message') || defaultError).replace('Error:', ''),
      testId: (error, i: number) => get(error, 'testId') || i,
    },
  ];

  return errorsListClean && errorsListClean.length > 0 ? (
    <Container data-testid={testId}>
      {errorsListClean.map((error, index) => {
        const errorConfig = errorsConfig.find(({ condition }) =>
          condition(error),
        );
        const errorId = errorConfig?.testId(error, index);
        const errorElement = errorConfig?.value(error);

        return (
          <Row data-testid={errorId} key={index}>
            {errorElement as any}
          </Row>
        );
      })}
    </Container>
  ) : null;
};
