import { theme } from '../../common/theme';
import { styled } from '../../providers/context/StyletronProvider/withStyletronProvider';

const getPagePadding = (isDesktop: boolean) =>
  isDesktop
    ? {
        paddingLeft: theme.unit[6],
        paddingRight: theme.unit[6],
      }
    : {
        paddingLeft: theme.unit[3],
        paddingRight: theme.unit[3],
      };

export const LayoutContainer = styled(
  'div',
  ({ $isDesktop, $modalOpened, $height, $isMobile }: any) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: $isDesktop ? '320px 1fr' : '1fr',
    gridTemplateRows: '2fr',
    height: $height,
    ...(!$isMobile ? { paddingLeft: 'calc(100vw - 100%)' } : {}),
  }),
);
export const PageContainer = styled('div', ({ $isDesktop }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: '0 auto',
  ...($isDesktop
    ? {
        maxWidth: '1116px',
      }
    : {
        minHeight: '100vh',
      }),
}));

export const PageContentContainer = styled('div', ({ $isDesktop }: any) => ({
  paddingBottom: theme.unit[6],
  ...getPagePadding($isDesktop),
}));

export const FooterContainer = styled('div', ({ $isDesktop, $theme }: any) => ({
  marginTop: 'auto',
  height: theme.unit[15],
  color: $theme.colors.primaryActive,
  ...getPagePadding($isDesktop),
}));

export const PageHamburger = styled('div', {
  position: 'absolute',
  top: theme.unit[4],
  marginLeft: theme.unit[3],
}) as any;

export const ErrorsContainer = styled('div', ({ $isDesktop }: any) => ({
  marginTop: $isDesktop ? theme.unit[4] : theme.unit[8],
}));
