import { useContext } from 'react';

import { theme } from '../../../common/theme';
import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { Icon } from '../Icon/Icon';
import { IconTypes } from '../Icon/iconTypes';
import { Menu } from '../Menu/Menu';
import { Back, Close, Container, IconContainer, LeftContent } from './styles';

export interface HeaderProps {
  onBack?: (data?: any) => void;
  onClose?: () => void;
  showBack?: boolean;
  showClose?: boolean;
  showInfo?: boolean;
  showMenu?: boolean;
  onInfoHandler?: () => void;
  isModal?: boolean;
  navConfig?: any;
}

const HeaderComponent = ({
  showBack,
  showClose = true,
  onBack,
  onClose = () => {},
  showInfo,
  showMenu,
  onInfoHandler,
  isModal,
  navConfig,
}: HeaderProps) => {
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);

  return (
    <Container>
      <LeftContent>
        {showMenu ? <Menu navConfig={navConfig} collapsedView /> : null}
        {showBack !== false && onBack ? (
          <Back data-testid="Previous" onClick={() => onBack()}>
            <Icon height={theme.unit[3]} name={IconTypes.ARROW} />
          </Back>
        ) : null}
      </LeftContent>

      {!!showClose ? (
        <Close
          data-testid={`${isModal ? 'popup' : ''}Close`}
          onClick={() => onClose()}
        >
          <Icon height={theme.unit[3]} name={IconTypes.DANGER} />
        </Close>
      ) : null}
      {!!showInfo ? (
        <IconContainer data-testid="info" onClick={onInfoHandler}>
          <Icon
            color={$theme.colors.primary}
            height={theme.unit[3]}
            name={IconTypes.TOOLTIP}
          />
        </IconContainer>
      ) : null}
    </Container>
  );
};

export const Header = HeaderComponent;
