import * as Sentry from '@sentry/react';
import { Event } from '@sentry/types';
import { FC } from 'react';

import { config } from '../../../config';
import { UserContextT } from '../CurrentUserContext/CurrentUserContextT';
import { ErrorReportingContext } from './ErrorReportingContext';

interface WrappedComponentProps {
  [key: string]: any;
}

export const withErrorReportingContextProvider = (
  WrappedComponent: FC<WrappedComponentProps>,
) => {
  Sentry.init(config.sentry);

  return (props: WrappedComponentProps) => {
    const setErrorsUser = (user: UserContextT) => {
      Sentry.setUser({
        id: user.id,
        email: user.primaryEmailAddress,
      });
    };

    const setErrorsAdditionalData = (
      additionalData: Record<string, unknown>,
    ) => {
      Sentry.setExtras(additionalData);
    };

    const captureException = (exception: string | Error, context?: Event) => {
      if (typeof exception === 'string') {
        Sentry.captureException(new Error(exception), context);
      } else {
        Sentry.captureException(exception, context);
      }
    };

    return (
      <ErrorReportingContext.Provider
        value={{ setErrorsUser, setErrorsAdditionalData, captureException }}
      >
        <WrappedComponent {...props} />
      </ErrorReportingContext.Provider>
    );
  };
};
